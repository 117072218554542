import React from 'react';
import {
    Box, Typography, Link, Divider, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Layout, TopNavigation } from 'components';
import AppIcons from '../components/AppIcons';
import FreeTvIcons from '../components/FreeTvIcons';

import image1 from 'images/player1.jpg';
import image2 from 'images/michael-hutchence.jpg';
import image3 from 'images/site-green.jpg';
import image4 from 'images/Janis-Joplin.jpg';
import DocuWhite from 'images/DOCU-white.svg';

export default function Info({ location }) {
    const seo = {
        title: "DOCUMENTARY+",
        description: `Home to the world’s best documentaries -- from Academy 
    Award winning classics and box office hits to festival darlings and cult favorites. 
    Nonfiction storytelling at its best, curated by the best.`,
    };
    const classes = useStyles();

    const imageGrid = [


        { img: image3, alt: "", href: "", height: "", width: "" },
        { img: image2, alt: "", href: "", height: "", width: "" },
        { img: image1, alt: "", href: "", height: "", width: "" },
        { img: image4, alt: "", href: "", height: "", width: "" },

    ]
    return (
        <Layout location={location} seo={seo} isFooterIcons={false}>
            <TopNavigation />
            <Grid item xs={12} md={12} lg={12}>
                   
                    <Box textAlign="center" mt={6} mb={3}>
                        <img alt="Documentry+" src={DocuWhite} style={{ width: "250px" }} />
                    </Box>
                    <Box textAlign="center" my={3}>
                        <Typography variant="h5">Watch the best documentaries.</Typography>
                        <Typography variant="h5">Completely Free.</Typography>
                    </Box>
                </Grid>
            <Grid container direction="row"
                    justifyContent="center"
                    alignItems="center" className={classes.image} >
                    {imageGrid.map((item, index) => {
                        return (<Grid className={classes.imageItem} key={`${index}`} item md={3} lg={3} xs={6} >
                            <Box textAlign="center">
                                <img alt={`image${index}`} height={item.height} width={item.width} src={item.img} />
                            </Box>
                        </Grid>)
                    })}
                </Grid>
            <Grid container justifyContent="center" className={classes.wrapper}>
                <Grid className={classes.whereToWatchGrid} item xs={12} md={12} lg={12}>
                    <Box className={classes.whereToWatchBox} textAlign="center" my={4}>
                        <Typography className={classes.whereToWatch} variant="h5">WHERE TO WATCH</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Box textAlign="center" mt={3}>
                        <Typography variant="h6" >Apps</Typography>
                        {/* <Divider className={classes.dividerSmall} /> */}
                    </Box>
                </Grid>

                <AppIcons />

                <Grid item xs={12} md={12} lg={12}>
                    <Box textAlign="center" mt={3}>
                    <Divider className={classes.dividerSmall} />
                    <Typography variant="h6">Free TV</Typography>
                    </Box>
                </Grid>

                <FreeTvIcons />

          
                <Divider className={classes.divider} />
                <Grid item xs={12} md={12} lg={12}>
                    <Box textAlign="center" my={6} >
                        <Typography variant="h3">CONTACT</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Box textAlign="center" >
                        <Typography
                            className={classes.text}
                            variant="h5">
                            For general inquiries:
                            <Link href="mailto:info@docplus.com"
                                className={classes.text}
                            >info@docplus.com</Link></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Box textAlign="center" mb={6} mt={2}>
                        <Typography
                            className={classes.text}
                            variant="h5">
                            For press inquiries:
                            <Link href="mailto:amy@xtr.com"
                                className={classes.text}
                            >amy@xtr.com</Link></Typography>
                    </Box>
                </Grid>

            </Grid>

        </Layout>
    );
}

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        alignItems: 'center',
    },  image: {
        height: '15px',
      }, svgLogo:{ width:'250px', marginTop: '42px',
        
      marginBottom: '18px',},
    whereToWatchGrid: {
        display: 'flex',
        justifyContent: 'center'
    },
    whereToWatch: {
        color: "black", 
        letterSpacing: '1px',
        fontSize: '16px',
        fontWeight: 500,
    },
    whereToWatchBox: {
        background: "white",
        width: '240px',
        height: '36px',
        justifyContent: 'center',
        paddingTop: '8px',
        borderRadius: '4px'
    },
    imageItem: {
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.between(0, 600)]: {
            height: '55vh'
        },
        height: '80vh'
    },
    bottomLabel: {
        bottom: 0
    },
    text: {
        padding: 8
    },
    divider: {
        backgroundColor: theme.body.color,
        width: '80%',
        margin: 16,
        marginTop: '70px'
    },
    dividerSmall: {
        backgroundColor: theme.body.color,
        width: '80%',
        margin: '16px auto'
    },
    logo: {
        padding: "15px 15vw",
        justifyContent: "space-between",
        display: 'flex',
        flexDirection: 'row'

    },
    logoItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 2px'
    },
    image: {
        padding: "6px 1vw 6px",
        justifyContent: " space-between",
        '& img': {
            maxWidth: "96%"
        }
    },
    wrapper: {
        paddingBottom: '6vw'
    }
}));